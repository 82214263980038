<script>
import ApiService from "@/services/api.service";
import moment from "moment";
export default {
  data() {
    return {
      transferDate: null,
      date: null,
      endDate: null,
      tempTransactionId: [],
      ids: [],
      transactions: [],
      valueCheckbox: {},
      visitedPage: {},
      form: {},
      totalPrice: 0,
      filter: "",
      status: false,
      responseDate: {
        start: null,
        end: null,
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
    };
  },
  mounted() {
    this.transferDate = moment().format("YYYY-MM-DD");
    this.date = moment().format("YYYY-MM-DD");
    this.endDate = moment().add(1, "day").format("YYYY-MM-DD");

    this.getModalTransactionsList();
  },
  methods: {
    openModal() {
      this.$refs["add-modal"].toggle();
      this.totalPrice = 0;
      this.transferDate = moment().format("YYYY-MM-DD");
      this.ids = [];
      this.visitedPage = {};
      this.status = false;
    },
    async submitForm() {
      await this.addFundTransfer();
      this.$parent.getFundTransferList();
    },
    async addFundTransfer() {
      this.form.fund_for_transaction_date = this.date; // testing purpose
      this.form.fund_transferred_at = this.transferDate;
      this.form.amount = this.totalPrice;
      this.form.transferId = this.ids;
      return await ApiService.post(`ez-qurban/fund-transfer`, this.form).then(
        (response) => {
          this.getModalTransactionsList();
        }
      );
    },
    getModalTransactionsList() {
      ApiService.get(
        `ez-qurban/transaction?start_date=${this.date}&end_date=${this.endDate}&status=2&limit=${this.pagination.perPage}&page=${this.pagination.currentPage}`
      ).then((response) => {
        this.transactions = response.data.transactions.data;
        this.pagination.totalRows = response.data.transactions.total;
        this.responseDate.start = response.data.dates.start;
        this.responseDate.end = response.data.dates.end;
      });
    },
    pageClicks(page) {
      this.pagination.currentPage = page;
      // console.log("Current page status: ", this.visitedPage);
      // console.log("Checkbox value: ", this.valueCheckbox);
      this.status = this.visitedPage[this.pagination.currentPage];
      this.getModalTransactionsList();
    },
    changePerPage() {
      this.pagination.currentPage = 1;
      this.status = false;
      this.ids = [];
      this.getModalTransactionsList();
    },
    updateList() {
      this.status = false;
      this.totalPrice = 0;
      this.ids = [];
      this.getModalTransactionsList();
    },
    filterSearch() {
      let table = document.querySelector("#table");
      let tableRow = table.getElementsByTagName("tr");
      let filterInput = this.filter.toUpperCase();

      // console.log("Search filter on");
      // console.log("Search input value: ", filterInput);
      // Loop through all table rows, and hide those who don't match the search query
      for (let i = 1; i < tableRow.length; i++) {
        let tds = tableRow[i].getElementsByTagName("td");

        let textValue = tds[1].textContent || tds[1].textContent;
        // console.log("Text value:", textValue);
        if (textValue.toUpperCase().indexOf(filterInput) > -1) {
          tableRow[i].style.display = "";
        } else {
          tableRow[i].style.display = "none";
        }
      }
    },
    selectIndividual(item) {
      /** Initialization */
      var transaction = item;
      this.status = false;

      // var inputElements = document.getElementsByTagName("input");
      // for (var i = 0; i < inputElements.length; i++) {
      //   if (
      //     inputElements[i].type == "checkbox" &&
      //     inputElements[i].checked == true &&
      //     inputElements[i].value == transaction.id
      //   ) {
      //     this.visitedPage[this.pagination.currentPage] = false;
      //     this.ids.push(inputElements[i].value);
      //     this.totalPrice += transaction.price;
      //   } else if (
      //     inputElements[i].type == "checkbox" &&
      //     inputElements[i].checked == false &&
      //     inputElements[i].value == transaction.id
      //   ) {
      //     let index = this.ids.indexOf(inputElements[i].value);
      //     this.ids.splice(index, 1);
      //     this.totalPrice -= transaction.price;
      //   }
      // }
      // console.log("Test inputs:", this.ids);
      let table = document.querySelector("#table");
      let tableRow = table.getElementsByTagName("tr");

      /** Checking for checkbox input */
      for (var i = 0; i < tableRow.length; i++) {
        var inputs = tableRow[i].querySelectorAll("input[type=checkbox]");

        for (var input of inputs) {
          if (input.checked) {
            this.visitedPage[this.pagination.currentPage] = false;
            if (input.value == transaction.id) {
              this.totalPrice += transaction.price;
              // this.ids.splice(this.ids.length, 0, input.value.toString());
              this.ids.push(input.value.toString());
              // this.ids[this.ids.length] = input.value;
            }
          } else {
            if (input.value == transaction.id) {
              let index = this.ids.indexOf(input.value);
              this.ids.splice(index, 1);
              this.totalPrice -= transaction.price;
            }
          }
        }
      }
      // this.ids = data;
      // console.log("Selected individual:", this.ids);
      // console.log("Selected individual length:", this.ids.length);
    },
    selectAll() {
      // this.ids = [];

      let table = document.querySelector("#table");
      let tableRow = table.getElementsByTagName("tr");
      let inputArray = [];

      this.visitedPage[this.pagination.currentPage] = this.status;
      this.valueCheckbox[this.pagination.currentPage] = inputArray;

      /** Get all checkbox input */
      for (var i = 1; i < tableRow.length; i++) {
        var tds = tableRow[i].getElementsByTagName("td");
        var inputs = tableRow[i].querySelectorAll("input[type=checkbox]");
        var price = parseInt(tds[3].textContent);

        for (var input of inputs) {
          inputArray[i] = input.value;
          if (this.visitedPage[this.pagination.currentPage] == true) {
            let index = this.ids.indexOf(inputArray[i]);
            if (
              this.ids[index] ==
              this.valueCheckbox[this.pagination.currentPage][i]
            ) {
              continue;
            } else {
              this.ids.push(input.value);
              this.totalPrice += price;
            }
          } else {
            this.totalPrice -= price;
            let index = this.ids.indexOf(input.value);
            this.ids.splice(index, 1);
          }
        }
      }
      // console.log("Selected ids from select all:", this.ids);
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toHumanDate: function (value) {
      return moment(value).format("D MMMM YYYY");
    },
    toHumanDateTime: function (value) {
      if (value) {
        return moment(value).format("D MMM YY, HH:mm:ss");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 14rem;
  overflow: auto;
  display: inline-block;
}
</style>

<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="add-modal"
      size="lg"
      title="Pindah Dana"
      ok-title="Simpan"
      cancel-title="Batal"
      @ok="submitForm"
    >
      <div class="form-group">
        <label for="">{{ $t("verified-clients-starting-at") }}</label>
        <input
          type="date"
          name=""
          id=""
          class="form-control"
          v-model="date"
          @change="updateList"
        />
      </div>
      <div class="form-group">
        <label for="">{{ $t("search-ref-no") }}</label>
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="text"
          @keyup="filterSearch"
        ></b-form-input>
      </div>
      <div class="table-responsive bg-white shadow rounded mt-3 table-wrapper">
        <table
          id="table"
          class="table mb-0 table-center table-bordered"
          style="font-size: 11px"
        >
          <thead class="bg-light">
            <tr>
              <th rowspan="2">{{ $t("index") }}</th>
              <th rowspan="2">{{ $t("ref-no") }}</th>
              <th rowspan="2" class="text-center">{{ $t("client") }}</th>
              <th rowspan="2" class="text-center">{{ $t("org.nav3") }} (RM)</th>
              <th rowspan="2">{{ $t("system-date") }}</th>
              <th rowspan="2">
                <label for="checkboxSelectAll">
                  <input
                    id="checkboxSelectAll"
                    name="check"
                    type="checkbox"
                    v-model="status"
                    @change="selectAll"
                  />
                  {{ $t("select-all") }}
                </label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="transactions && transactions.length == 0">
              <td class="text-center" colspan="6">
                <h6 class="mt-5 mb-5">{{ $t("no-data") }}</h6>
              </td>
            </tr>
            <tr v-for="(transaction, index) in transactions" :key="index">
              <template
                v-if="
                  transaction.verified_at != null &&
                  transaction.fund_transfer_id == null
                "
              >
                <td>
                  {{
                    (pagination.currentPage - 1) * pagination.perPage +
                    index +
                    1
                  }}
                </td>
                <td>{{ transaction.ref_no }}</td>
                <td>
                  {{ transaction.client_name }}
                </td>
                <td class="text-right">{{ transaction.price }}</td>
                <td>
                  {{ transaction.created_at | toHumanDateTime }}
                </td>
                <td class="text-center">
                  <input
                    name="check"
                    type="checkbox"
                    v-model="ids"
                    :value="transaction.id"
                    @click="selectIndividual(transaction)"
                  />
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Pagination -->
      <div class="row">
        <div class="col-lg-9 col-md-7">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            @change="pageClicks"
            aria-controls="my-table"
          ></b-pagination>
        </div>
        <div class="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="form custom-form">
            <div class="form-group mb-0">
              <select
                v-model="pagination.perPage"
                class="form-control custom-select"
                id="Sortbylist-job"
                @change="changePerPage()"
              >
                <option value="10">10 {{ $t("per-page") }}</option>
                <option value="50">50 {{ $t("per-page") }}</option>
                <option value="75">75 {{ $t("per-page") }}</option>
                <option value="100">100 {{ $t("per-page") }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="">{{ $t("funds-transferred-on") }}</label>
        <div class="row">
          <div class="col-md-12">
            <input
              type="date"
              name=""
              id=""
              class="form-control"
              v-model="transferDate"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="">{{ $t("total") }}</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">RM</span>
          </div>
          <input type="text" class="form-control" v-model="totalPrice" />
        </div>
      </div>
    </b-modal>
  </div>
</template>