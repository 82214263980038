<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import { ArrowUpIcon } from "vue-feather-icons";
import ApiService from "@/services/api.service";
import moment from "moment";
import { mapGetters } from "vuex";
import AddFundModal from "./add-fund-modal.vue";
import ShowClientModal from "./show-client-modal.vue";

export default {
  data() {
    return {
      transactions: [],
      fundTransfers: [],
      transactionClients: [],
      date: null,
      endDate: null,
      selectedMonth: null,
      month: null,
      year: null,
      status: 0,
      transactionCount: 0,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
      total: {
        sumPending: 0,
        sumVerified: 0,
        sumFundTransfer: 0,
      },
      responseDate: {
        start: null,
        end: null,
      },
    };
  },
  components: {
    Navbar,
    ArrowUpIcon,
    Footer,
    AddFundModal,
    ShowClientModal,
  },
  mounted() {
    if (this.$route.query.date) {
      this.selectedMonth = moment(this.$route.query.month).format("YYYY-MM");
      // this.date = this.$route.query.date;
      this.date = moment(this.$route.query.date)
        .startOf("month")
        .format("YYYY-MM-DD");
      this.endDate = moment(this.$route.query.date).format("YYYY-MM-DD");
    } else {
      this.selectedMonth = moment().format("YYYY-MM");
      // this.date = moment().format("YYYY-MM-DD");
      this.date = moment().startOf("month").format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
    }

    let monthArray = this.selectedMonth.split("-");
    this.month = monthArray[1];
    this.year = monthArray[0];

    this.getTransactionsList();
    this.getFundTransferList();
  },
  methods: {
    getTransactionsList() {
      ApiService.get(
        `ez-qurban/transaction?start_date=${this.date}&end_date=${this.endDate}&report_verification=true`
        // `ez-qurban/transaction?start_date=${this.date}&end_date=${this.endDate}&report_verification=true&limit=1`
      ).then((response) => {
        this.transactions = response.data.transactions.data;
        this.pagination.totalRows = response.data.transactions.total;
        this.total.sumPending = response.data.sum_pending;
        this.total.sumVerified = response.data.sum_verified;
        this.responseDate.start = response.data.dates.start;
        this.responseDate.end = response.data.dates.end;
      });
    },
    getFundTransferList() {
      ApiService.get(
        `ez-qurban/fund-transfer?date=${this.date}&month=${this.month}&year=${this.year}&report_transfer=true&limit=${this.pagination.perPage}&&page=${this.pagination.currentPage}`
      ).then((response) => {
        this.fundTransfers = response.data.fund_transfers.data;
        this.transactionClients = response.data.transaction_clients;
        this.transactionCount = response.data.transaction_clients_count;
        this.pagination.totalRows = response.data.fund_transfers.total;
        this.total.sumFundTransfer = response.data.sum;
      });
    },
    pageClicks(page) {
      this.pagination.currentPage = page;
      this.getTransactionsList();
      this.getFundTransferList();
    },
    changePerPage() {
      this.pagination.currentPage = 1;
      this.getTransactionsList();
      this.getFundTransferList();
    },
    search(status) {
      this.pagination.currentPage = 1;
      if (status != null) {
        this.status = status;
      }

      let monthArray = this.selectedMonth.split("-");
      this.month = monthArray[1];
      this.year = monthArray[0];

      let tempMonth = moment().format("YYYY-MM");
      this.date = moment(this.selectedMonth)
        .startOf("month")
        .format("YYYY-MM-DD");
      if (this.selectedMonth != tempMonth) {
        this.endDate = moment(this.selectedMonth)
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        this.endDate = moment().format("YYYY-MM-DD");
      }

      this.getTransactionsList();
      this.getFundTransferList();
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toHumanDate: function (value) {
      return moment(value).format("D MMM YY");
    },
    toHumanDateTime: function (value) {
      if (value) {
        return moment(value).format("D MMM YY, HH:mm:ss");
      }
      return "";
    },
  },
  events: {
    eventName: function (data) {
      this.getFundTransferList();
    },
  },
  watch: {
    date: {
      handler: function (val) {
        this.endDate = moment(val).add(1, "day").format("YYYY-MM-DD");
      },
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userAccess"]),
    orgCode() {
      return this.userAccess?.organization?.code;
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white" style="text-transform: capitalize">
                {{ $t("ezq-fund") }} 
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div
        class="
          container
          card
          public-profile
          border-0
          rounded
          shadow
          overflow-hidden
        "
      >
        <div class="card-body">
          <div class="row mt-5">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label"
                  >{{ $t("month") }}</label
                >
                <div class="col-sm-8 col-md-8">
                  <input
                    type="month"
                    class="form-control"
                    name=""
                    id=""
                    v-model="selectedMonth"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12 text-right">
              <button @click="search(null)" class="btn btn-primary">
                {{ $t("search2") }}
              </button>
            </div>
          </div>
          <hr />

          <h5 class="text-center">{{ $t("funds-transferred-at") }} </h5>
          <h6 class="text-center">
            {{ responseDate.start | toHumanDateTime }} -
            {{ responseDate.end | toHumanDateTime }}
          </h6>
          <div class="row">
            <div class="col-md-4">
              <a
                href="#"
                class="accounts rounded d-block shadow text-center py-3"
              >
                <h3>
                  <small style="font-size: 9pt">RM </small>
                  {{ total.sumFundTransfer.toFixed(2) }} 
                </h3>
                <h6 class="title text-dark h6 my-0">{{ $t("funds-transferred") }}</h6></a
              >
            </div>
            <div class="col-md-4">
              <router-link
                :to="{
                  name: 'ezqurban.transaction.list',
                  query: {
                    status: 1,
                    date: this.date,
                  },
                }"
                class="accounts rounded d-block shadow text-center py-3"
              >
                <h3>
                  <small style="font-size: 9pt">RM </small>
                  {{ total.sumVerified.toFixed(2) }}
                </h3>
                <h6 class="title text-dark h6 my-0">{{ $t("verified-tx") }}</h6>
              </router-link>
            </div>
            <div class="col-md-4">
              <router-link
                :to="{
                  name: 'ezqurban.transaction.list',
                  query: {
                    status: 0,
                    date: this.date,
                  },
                }"
                class="accounts rounded d-block shadow text-center py-3"
              >
                <h3>
                  <small style="font-size: 9pt">RM </small>
                  {{ total.sumPending.toFixed(2) }}
                </h3>
                <h6 class="title text-dark h6 my-0">
                  {{ $t("tx-not-confirmed") }}
                </h6>
              </router-link>
            </div>
          </div>

          <div class="table-responsive bg-white shadow rounded mt-3">
            <table
              class="table mb-0 table-center table-bordered"
              style="font-size: 12px"
            >
              <thead class="bg-light">
                <tr>
                  <th>{{ $t("index") }}</th>
                  <th>{{ $t("total") }} (RM)</th>
                  <th>{{ $t("client") }}</th>
                  <th>{{ $t("transferred-on") }}</th>
                  <!-- <th>Untuk Transaksi</th> -->
                  <!-- <th class="text-right">{{ $t("action") }}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-if="fundTransfers && fundTransfers.length == 0">
                  <td class="text-center" colspan="6">
                    <h6 class="mt-5 mb-5">{{ $t("no-data") }}</h6>
                  </td>
                </tr>
                <tr v-for="(fund, index) in fundTransfers" :key="index">
                  <td>
                    {{
                      (pagination.currentPage - 1) * pagination.perPage +
                      index +
                      1
                    }}
                  </td>
                  <td>{{ fund.amount.toFixed(2) }}</td>
                  <td>
                    <span
                      style="cursor: grab; color: blue"
                      @click="
                        $refs.showClient.openModal(transactionClients[index])
                      "
                      >{{ transactionCount[index] }}</span
                    >
                  </td>
                  <td>{{ fund.fund_transferred_at | toHumanDate }}</td>
                  <!-- <td>{{ fund.fund_for_transaction_date | toHumanDate }}</td> -->
                  <!-- <td class="text-right">
                    <button class="btn btn-primary btn-sm">Lampiran</button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Pagination -->
          <div class="row">
            <div class="col-lg-9 col-md-7">
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.perPage"
                @change="pageClicks"
                aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="form custom-form">
                <div class="form-group mb-0">
                  <select
                    v-model="pagination.perPage"
                    class="form-control custom-select"
                    id="Sortbylist-job"
                    @change="changePerPage()"
                  >
                    <option value="5">5 {{ $t("per-page") }}</option>
                    <option value="10">10 {{ $t("per-page") }}</option>
                    <option value="25">25 {{ $t("per-page") }}</option>
                    <option value="50">50 {{ $t("per-page") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4" v-if="orgCode != 'EZQ'">
        <button @click="$refs.addFund.openModal()" class="btn btn-primary">
          {{ $t("add-fund-transfer") }}
        </button>
      </div>
    </section>

    <add-fund-modal ref="addFund" />
    <show-client-modal ref="showClient" />
    <Footer />
    <!-- <Switcher /> -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
