var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "add-modal",
    attrs: {
      "id": "modal-prevent-closing",
      "size": "lg",
      "title": "Pindah Dana",
      "ok-title": "Simpan",
      "cancel-title": "Batal"
    },
    on: {
      "ok": _vm.submitForm
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("verified-clients-starting-at")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.date,
      expression: "date"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date",
      "name": "",
      "id": ""
    },
    domProps: {
      "value": _vm.date
    },
    on: {
      "change": _vm.updateList,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.date = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("search-ref-no")))]), _c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "text"
    },
    on: {
      "keyup": _vm.filterSearch
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-3 table-wrapper"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-bordered",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "id": "table"
    }
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("ref-no")))]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("client")))]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav3")) + " (RM)")]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("system-date")))]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_c('label', {
    attrs: {
      "for": "checkboxSelectAll"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.status,
      expression: "status"
    }],
    attrs: {
      "id": "checkboxSelectAll",
      "name": "check",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.status) ? _vm._i(_vm.status, null) > -1 : _vm.status
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.status,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.status = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.status = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.status = $$c;
        }
      }, _vm.selectAll]
    }
  }), _vm._v(" " + _vm._s(_vm.$t("select-all")) + " ")])])])]), _c('tbody', [_vm.transactions && _vm.transactions.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e(), _vm._l(_vm.transactions, function (transaction, index) {
    return _c('tr', {
      key: index
    }, [transaction.verified_at != null && transaction.fund_transfer_id == null ? [_c('td', [_vm._v(" " + _vm._s((_vm.pagination.currentPage - 1) * _vm.pagination.perPage + index + 1) + " ")]), _c('td', [_vm._v(_vm._s(transaction.ref_no))]), _c('td', [_vm._v(" " + _vm._s(transaction.client_name) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(transaction.price))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("toHumanDateTime")(transaction.created_at)) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.ids,
        expression: "ids"
      }],
      attrs: {
        "name": "check",
        "type": "checkbox"
      },
      domProps: {
        "value": transaction.id,
        "checked": Array.isArray(_vm.ids) ? _vm._i(_vm.ids, transaction.id) > -1 : _vm.ids
      },
      on: {
        "click": function ($event) {
          return _vm.selectIndividual(transaction);
        },
        "change": function ($event) {
          var $$a = _vm.ids,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = transaction.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.ids = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.ids = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.ids = $$c;
          }
        }
      }
    })])] : _vm._e()], 2);
  })], 2)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-9 col-md-7"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v("10 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v("50 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "75"
    }
  }, [_vm._v("75 " + _vm._s(_vm.$t("per-page")))]), _c('option', {
    attrs: {
      "value": "100"
    }
  }, [_vm._v("100 " + _vm._s(_vm.$t("per-page")))])])])])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("funds-transferred-on")))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.transferDate,
      expression: "transferDate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date",
      "name": "",
      "id": ""
    },
    domProps: {
      "value": _vm.transferDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.transferDate = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v("RM")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.totalPrice,
      expression: "totalPrice"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.totalPrice
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.totalPrice = $event.target.value;
      }
    }
  })])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }