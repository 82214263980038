var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "show-modal",
    attrs: {
      "id": "modal-prevent-closing",
      "size": "lg",
      "title": "Maklumat Pelanggan",
      "cancel-title": "Batal",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-3 table-wrapper"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-bordered",
    staticStyle: {
      "font-size": "11px"
    },
    attrs: {
      "id": "table"
    }
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("ref-no")))]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("client")))]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav3")) + " (RM)")]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("system-date")))])])]), _c('tbody', [_vm.transactions && _vm.transactions.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e(), _vm._l(_vm.transactions, function (transaction, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(_vm._s(transaction.ref_no))]), _c('td', [_vm._v(" " + _vm._s(transaction.client_name) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(transaction.price))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("toHumanDateTime")(transaction.created_at)) + " ")])]);
  })], 2)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }